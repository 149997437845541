import { initBodyScroll, killBodyScroll } from './common/overlayScrollbars';
import { OverlayScrollbars } from 'overlayscrollbars';
import { slideToggle, slideUp } from './extends/components/slideToggle';

document.addEventListener( 'DOMContentLoaded', function() {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty( '--app-height', `${ window.visualViewport.height }px` );
  };

  window.visualViewport.addEventListener( 'resize', appHeight );
  window.addEventListener( 'scroll', appHeight );
  appHeight();

  initBodyScroll();

  const currentUrl = window.location.href;
  if ( currentUrl.includes( '%5B%5D' ) ) {
    const updatedUrl = currentUrl.replace( /%5B%5D/g, '[]' );
    window.history.replaceState( null, null, updatedUrl );
  }

  const navScroll = [];
  const siteHeader = document.getElementById( 'masthead' );
  const mobileNav = document.querySelector( '.site-header__nav' );
  const hamburger = document.getElementById( 'hamburger' );

  hamburger?.addEventListener( 'click', function( e ) {
    if ( hamburger.classList.contains( 'active' ) ) {
      hamburger.classList.remove( 'active' );
      siteHeader.classList.remove( 'active' );
      mobileNav.classList.remove( 'active' );
      initBodyScroll();
    } else {
      hamburger.classList.add( 'active' );
      siteHeader.classList.add( 'active' );
      mobileNav.classList.add( 'active' );
      killBodyScroll();
    }
  } );

  let windowWidth = window.innerWidth;
  window.addEventListener(
    'resize',
    function( event ) {
      windowWidth = window.innerWidth;
    },
    true
  );

  if ( windowWidth < 1024 ) {
    document.addEventListener( 'click', ( e ) => {
      if ( ! e.target.matches( '.nav-link.dropdown-toggle' ) ) {
        return;
      }
      e.preventDefault();

      const mobileParents = [ ...document.querySelectorAll( '.nav-link' ) ];
      const otherElements = mobileParents.filter( function( element ) {
        return element !== e.target;
      } );

      for ( let i = 0; i < otherElements.length; i++ ) {
        if ( otherElements[ i ].nextElementSibling ) {
          slideUp( otherElements[ i ].nextElementSibling, 200 );
          otherElements[ i ].parentElement.classList.remove( 'open' );
        }

        if ( e.target.parentElement.classList.contains( 'open' ) ) {
          otherElements[ i ].classList.remove( 'other' );
        } else {
          otherElements[ i ].classList.add( 'other' );
        }
      }

      slideToggle( e.target.nextElementSibling, 500 );
      e.target.parentElement.classList.toggle( 'open' );
      e.target.classList.remove( 'other' );
    } );
  }
  if ( windowWidth < 768 ) {
    document.addEventListener( 'click', ( e ) => {
      if ( ! e.target.matches( '.site-footer__nav > ul > li > a' ) ) {
        return;
      }
      e.preventDefault();

      const mobileParents = [ ...document.querySelectorAll( '.site-footer__nav > ul > li > a' ) ];
      const otherElements = mobileParents.filter( ( el ) => el !== e.target );

      for ( let i = 0; i < otherElements.length; i++ ) {
        if ( otherElements[ i ].nextElementSibling ) {
          slideUp( otherElements[ i ].nextElementSibling, 200 );
        }
      }

      slideToggle( e.target.nextElementSibling, 500 );
      e.target.parentElement.classList.toggle( 'open' );
    } );
  }

  document.addEventListener( 'click', ( e ) => {
    if ( e.target.matches( '.nav-item a[href*="#"]' ) ) {
      if ( hamburger.classList.contains( 'active' ) ) {
        hamburger.classList.remove( 'active' );
        siteHeader.classList.remove( 'active' );
        mobileNav.classList.remove( 'active' );
        initBodyScroll();
      }
    }
  } );
} );

const scrollToHash = ( hash ) => {
  if ( hash ) {
    const targetElement = document.querySelector( hash );
    if ( targetElement ) {
      const fadeElements = document.querySelectorAll( '.fade' );
      fadeElements.forEach( ( fadeElement ) => {
        fadeElement.classList.add( 'show' );
      } );

      const offset = 150;
      const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      // Scroll by 1px to trigger user interaction
      window.scrollBy( 0, 1 );

      setTimeout( () => {
        window.scrollTo( {
          top: offsetPosition,
          behavior: 'smooth',
        } );
      }, 1000 );
    }
  }
};

document.addEventListener( 'DOMContentLoaded', () => {
  const scrollNavBlock = document.querySelector( '.scroll-nav' );

  if ( window.location.hash ) {
    const originalHash = window.location.hash;
    window.history.replaceState( null, null, ' ' );

    setTimeout( () => {
      scrollToHash( originalHash );
    }, 0 );
  }

  // Handle click on any hash link, excluding links within the scrollNavBlock
  document.querySelectorAll( 'a[href^="#"]' ).forEach( ( anchor ) => {
    anchor.addEventListener( 'click', ( event ) => {
      const hash = anchor.getAttribute( 'href' );
      if ( hash !== '#' && ( ! scrollNavBlock || ! scrollNavBlock.contains( anchor ) ) ) {
        event.preventDefault();
        window.history.pushState( null, null, hash );
        scrollToHash( hash );
      }
    } );
  } );

  // Listen to hashchange event to handle back/forward navigation
  window.addEventListener( 'hashchange', () => {
    scrollToHash( window.location.hash );
  } );
} );

document.addEventListener( 'DOMContentLoaded', () => {
  const formContainer = document.querySelector( '.single__content--form' );

  if ( formContainer ) {
    const buffer = 100; // Additional buffer for the height check

    const checkFormHeight = () => {
      const formHeight = formContainer.offsetHeight;
      const viewportHeight = window.innerHeight;

      if ( formHeight + buffer <= viewportHeight ) {
        formContainer.classList.remove( 'bottom' );
      } else {
        formContainer.classList.add( 'bottom' );
      }
    };

    // Observe changes in the size of the form container
    const resizeObserver = new ResizeObserver( () => {
      checkFormHeight();
    } );

    resizeObserver.observe( formContainer );

    // Check on initial load and when the viewport is resized
    checkFormHeight();
    window.addEventListener( 'resize', checkFormHeight );
  }
} );
